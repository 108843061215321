<template>
  <div class="space-y-2">
    <inputs-label :for="id" :title="`${title} ${required ? '*' : ''}`"/>

    <div class="relative">
      <input :id="id"
             :autofocus="autofocus"
             :name="name ?? id"
             :placeholder="placeholder"
             :required="required"
             :type="show_password ? 'text': 'password'"
             autocomplete="off"
             class="appearance-none w-full input"
             @keyup="emit('set', $event)">

      <div class="absolute inset-y-0 right-0 flex items-center pr-3">
        <icon :class="show_password ? 'text-primary-500' : 'text-gray-400'" class="hover:cursor-pointer"
              name="ic:outline-remove-red-eye"
              size="14"
              @click="show_password = !show_password"/>
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
  id: {
    type: String,
    required: true,
  },

  title: {
    type: String,
    required: true,
  },

  autofocus: {
    type: Boolean,
    required: false,
  },

  name: {
    type: String,
    required: false,
  },

  placeholder: {
    type: String,
    required: false,
  },

  required: {
    type: Boolean,
    required: false,
  },
})

const emit = defineEmits(['set'])

const show_password = ref(false)
</script>
